<!-- front/NewsItems.client.vue-->

<template>
  <div class="news-container">
    <div class="grid-1-1-1-1">
      <div v-for="news in dummyNews" :key="news.id" class="item">
        <div class="">
          <h3>{{ news.title }}</h3>
          <p>{{ news.description }}</p>
          <p class="small date">{{ news.date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const dummyNews = ref([
  {
    id: 1,
    title: 'News Title 1',
    description: 'This is a short description of news item 1.',
    date: '2023-10-01',
  },
  {
    id: 2,
    title: 'News Title 2',
    description: 'This is a short description of news item 2.',
    date: '2023-10-02',
  },
  {
    id: 3,
    title: 'News Title 3',
    description: 'This is a short description of news item 3.',
    date: '2023-10-03',
  },
  {
    id: 4,
    title: 'News Title 4',
    description: 'This is a short description of news item 4.',
    date: '2023-10-04',
  },
  {
    id: 5,
    title: 'News Title 5',
    description: 'This is a short description of news item 5.',
    date: '2023-10-05',
  },
  {
    id: 6,
    title: 'News Title 6',
    description: 'This is a short description of news item 6.',
    date: '2023-10-06',
  },
  {
    id: 7,
    title: 'News Title 7',
    description: 'This is a short description of news item 7.',
    date: '2023-10-07',
  },
  {
    id: 8,
    title: 'News Title 8',
    description: 'This is a short description of news item 8.',
    date: '2023-10-08',
  },
])
</script>

<style scoped>
.news-container {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.news-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: var(--text);
}

.item {
  flex: 1 1 calc(25% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  height: 200px;
  /* Ensure all items have the same height */
  border: 1px solid #ddd;
  /* Added border */
}

.item:hover {
  transform: translateY(-5px);
  background-color: #e9e9e9;
  /* Changed hover background color */
}

.item h3 {
  margin: 0 0 8px;
  font-size: 1.25em;
}

.date {
  margin-top: 12px;
  color: var(--text);
  opacity: 0.7;
}

/* Additional styles from the active file */
.item h2,
.item h3,
.item h4 {
  min-height: 4em;
}

.padding-1 {
  padding: 1rem;
}

h2 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #333;
}

@media (max-width: 1025px) {
  .item {
    width: 100%;
  }
}
</style>

<template>
  <div>
    <!-- Home slider events -->
    <div v-if="showHomeSlider" class="container-fluid">
      <FrontEventsSlider
        :showInHomeSlider="true"
        :isArchive="false"
        :isHomeFeatured="false"
        :perPage="isMobile ? 1 : 3"
      />
    </div>

    <!-- Search events 
    <div class="container-fluid secondary-bg sticky-top-searching-container">
      <div class="container">
        <FrontSearch @searching="handleSearching" />
      </div>
    </div>
    -->

    <!-- Archive events -->
    <div v-if="showEventsArchive" class="container-fluid section-padding">
      <div class="container">
        <FrontEventsList
          :isHomeFeatured="false"
          :isInYourTown="false"
          :isArchive="true"
        />
      </div>
    </div>

    <!-- Featured events -->
    <div v-if="showHomeFeatured" class="container-fluid section-padding">
      <div class="container">
        <FrontEventsList
          :isHomeFeatured="true"
          :isInYourTown="false"
          :isArchive="false"
        />
      </div>
    </div>

    <!-- Types -->
    <div v-if="showHomeTypes" class="container-fluid section-padding">
      <FrontTypeFilter />
    </div>

    <!-- In your town events -->
    <div v-if="showHomeInYourCity" class="container-fluid section-padding">
      <div class="container">
        <FrontEventsList :isHomeFeatured="false" :isInYourTown="true" />
      </div>
    </div>

    <!-- Genres -->
    <div v-if="showHomeCategories" class="container-fluid section-padding">
      <FrontGenreFilter />
    </div>

    <!-- Latest news list -->
    <div v-if="showHomeNews" class="container-fluid section-padding">
      <div class="container">
        <div class="section-title">
          <h2>{{ $t('latestNews') }}</h2>
        </div>
        <FrontNewsItems />
      </div>
    </div>

    <!-- Show mobile chart -->
    <FrontMobileCart />

    <!-- Overlay -->
    <div v-if="isSearching" class="overlay"></div>
  </div>
</template>

<script setup lang="ts">
import { useSettingsData } from '~/store/settings'

const { isMobile, isMobileInitialized } = useIsMobile()
const { t: $t } = useI18n()

const isSearching = ref(false)
const showEventsArchive = ref(false)
const showHomeSlider = ref(true)
const showHomeFeatured = ref(false)
const showHomeCategories = ref(false)
const showHomeTypes = ref(false)
const showHomeInYourCity = ref(false)
const showHomeNews = ref(false)

// Sticky searhing component is removed
/*
const handleSearching = (searching: any) => {
  isSearching.value = searching
}
*/

const route = useRoute()
const settingsStore = useSettingsData()
const runtimeConfig = useRuntimeConfig()

const getDesign = computed(() => settingsStore.design)
showEventsArchive.value = getDesign.value.data.showEventsArchive
showHomeSlider.value = getDesign.value.data.showHomeSlider
showHomeFeatured.value = getDesign.value.data.showHomeFeatured
showHomeCategories.value = getDesign.value.data.showHomeCategories
showHomeTypes.value = getDesign.value.data.showHomeTypes
showHomeInYourCity.value = getDesign.value.data.showHomeInYourCity
showHomeNews.value = getDesign.value.data.showHomeNews

// Set the head tags dynamically
useHead({
  title: `${runtimeConfig.public.companyName} | ${$t('slogan')}`,
  meta: [
    // { name: 'description', content: (data as any).page.description },
    {
      property: 'og:title',
      content: `${runtimeConfig.public.companyName} | ${$t('slogan')}`,
    },
    {
      property: 'og:image',
      content: `${runtimeConfig.public.baseURL}/default-social-share.jpg`,
    },
    {
      property: 'og:url',
      content: `${runtimeConfig.public.baseURL}${route.fullPath}`,
    },
    // Twitter Card Tags
    { name: 'twitter:card', content: 'summary_large_image' },
    {
      name: 'twitter:title',
      content: `${runtimeConfig.public.companyName} | ${$t('slogan')}`,
    },
    {
      name: 'twitter:image',
      content: `${runtimeConfig.public.baseURL}/default-social-share.jpg`,
    },
  ],
})

onMounted(() => {
  /*
  showHomeSlider.value = getDesign.value.data.showHomeSlider
  showHomeFeatured.value = getDesign.value.data.showHomeFeatured
  showHomeCategories.value = getDesign.value.data.showHomeCategories
  showHomeInYourCity.value = getDesign.value.data.showHomeInYourCity
  showHomeNews.value = getDesign.value.data.showHomeNews
  */
})
</script>

<style>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.primary-footer-container {
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
}

.page-container {
  padding: 2em 0;
}

.secondary-bg {
  background-color: var(--section-bg-color);
}

.section-padding {
  padding: 2em 0;
}
</style>

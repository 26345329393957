<!-- front/EventsSlider.client.vue-->
<template>
  <div class="splide" ref="splide">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide" v-for="(slide, index) in slides" :key="index">
          <NuxtLink
            v-if="!isEventExpired(slide)"
            :to="`/event/${slide.slug}?lang=${currentLocale}`"
          >
            <img
              class="poster img-cover"
              :src="slide.media[0].files.thumb480"
              :alt="slide.title"
              loading="lazy"
            />
          </NuxtLink>
          <img
            v-else
            class="poster img-cover"
            :src="slide.media[0].files.thumb480"
            :alt="slide.title"
            loading="lazy"
          />
        </li>
      </ul>
    </div>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev">
        <Icon name="dashicons:arrow-left-alt2"></Icon>
      </button>
      <button class="splide__arrow splide__arrow--next">
        <Icon name="dashicons:arrow-right-alt2"></Icon>
      </button>
    </div>
  </div>
</template>

<script setup>
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
// import type { IEvent } from '~/types'

// Define props
const props = defineProps({
  showInHomeSlider: {
    type: Boolean,
  },
  perPage: {
    type: Number,
    default: 2,
  },
})

const { locale } = useI18n()
const currentLocale = ref(locale.value)

const isEventExpired = (event) => {
  return new Date(event?.date) < new Date()
}

// Watch for changes in the locale to update the currentLocale
watch(locale, (newLocale) => {
  currentLocale.value = newLocale
})

const splide = ref(null)

const eventStore = useEventStoreFront()

const slides = ref([])

const params = ref({
  showInHomeSlider: props.showInHomeSlider,
})

// Get slides data
const getSliderData = async () => {
  try {
    const data = await eventStore.getSliders(params.value)
    slides.value = data.events ?? []
    console.log('slides', slides.value)
  } catch (error) {
    console.error(error)
  }
}

// Must be async
onMounted(async () => {
  await getSliderData()

  if (splide.value) {
    new Splide(splide.value, {
      type: 'loop',
      perPage: props.perPage,
      gap: '12px',
      autoplay: 'play',
      pauseOnHover: true,
      resetProgress: false,
      interval: 3000,
      arrows: true,
      pagination: false,
    }).mount()
  }
})
</script>

<style scoped>
.splide__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  height: 100%;
}

.splide__track {
  height: 300px;
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splide__slide a {
  height: 100%;
  width: 100%;
}

/* Center arrows vertically */
.splide__arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.splide__arrow {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.splide__arrow:hover {
  background: rgba(255, 255, 255, 1);
}

@media (max-width: 1025px) {
  .splide__track {
    height: auto;
  }
  .splide__slide img {
    object-fit: cover;
  }
}

@media (max-width: 1369px) {
  .splide__track {
    height: 230px;
  }
}

@media (min-width: 2000px) {
  .splide__track {
    height: 451px;
  }
  .splide__slide img {
    object-fit: cover;
  }
}
</style>

<!-- front/TypeFilter.client.vue-->

<template>
  <div class="filtered-events-container">
    <h2>{{ $t('yourFavouriteType') }}</h2>
    <div class="type-buttons grid-1-1-1-1">
      <button
        class="prelative"
        v-for="type in types"
        :key="type.value"
        @click="redirectTotype(type.value, type.label)"
        :class="{ active: selectedtype === type.value }"
      >
        <img :src="typeImages[type.value]" :alt="type.label" />
        <span class="type-label vertical-center">{{ type.label }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
const { t: $t, locale } = useI18n()

const emit = defineEmits(['type-selected'])

const router = useRouter()
const route = useRoute()

// Simple array shuffle
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

const types = computed(() => [
  { value: 'concert', label: $t('types.concert') },
  { value: 'theater', label: $t('types.theater') },
  { value: 'conference', label: $t('types.conference') },
  { value: 'sport', label: $t('types.sport') },
])

const typeImages = computed(() => {
  const images = {
    concert: '/img/concert-grid-item.jpg',
    theater: '/img/theater-grid-item.jpg',
    conference: '/img/conference-grid-item.jpg',
    sport: '/img/sport-grid-item.jpg',
  }
  return types.value.reduce((acc, type) => {
    acc[type.value] = images[type.value]
    return acc
  }, {})
})

const selectedtype = computed(() => route.query['selected-type'])

// Emit and set query params
const redirectTotype = (value, label) => {
  emit('type-selected', { value: value, label: label })
  router.push({
    path: '/event/type',
    query: {
      'selected-type': value,
      'selected-type-label': label,
      lang: locale.value,
    },
  })
}

// Watch for route param changes and update types
watch(
  () => route.query.lang,
  () => {
    // This will trigger the computed property to re-evaluate
    locale.value = route.query.lang
  }
)

onMounted(() => {
  // Show always random events on page load
  shuffleArray(types.value)
})
</script>

<style scoped>
.filtered-events-container {
  padding: 20px;
  background-color: #f0f0f0;
  position: relative;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.type-buttons.grid-1-1-1 {
  align-items: start;
}

.type-buttons button {
  width: 100%;
  height: 350px;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 0;
}

.type-buttons img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.type-buttons button:hover img {
  filter: grayscale(0);
}

.type-label {
  font-size: 1.6rem;
  font-weight: bold;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2em;
  text-transform: uppercase;
}

/*
.type-buttons button:nth-child(1) {
  height: 600px;
}

.type-buttons button:nth-child(2) {
  height: 320px;
}

.type-buttons button:nth-child(3) {
  height: 220px;
}

.type-buttons button:nth-child(4) {
  height: 350px;
}

.type-buttons button:nth-child(5) {
  height: 350px;
  margin-top: -280px;
}

.type-buttons button:nth-child(6) {
  height: 380px;
  margin-top: -380px;
}

.type-buttons button:nth-child(7) {
  height: 240px;
}

.type-buttons button:nth-child(8) {
  height: 520px;
  margin-top: -280px;
}

.type-buttons button:nth-child(9) {
  height: 589px;
  margin-top: -350px;
}
*/

.type-buttons button:hover {
  transform: scale(1.1);
}

.type-buttons button.active {
  background-color: var(--primary-color);
  transform: scale(1.1);
}

.events-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  max-height: 400px;
}

.event-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #ddd;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.event-item:hover {
  transform: translateY(-5px);
  background-color: #e9e9e9;
}

.event-item h3 {
  margin: 0 0 8px;
  font-size: 1.25em;
}

.event-item p {
  margin: 0;
}

.type,
.date {
  font-size: 0.875em;
  color: #888;
}

@media screen and (max-width: 1025px) {
  .type-buttons button {
    height: 300px !important;
  }
}
</style>
